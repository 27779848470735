<template>
  <div class="checkout-summary">
    <div class="checkout-summary-container">
      <div class="checkout-label">
        ยอดรวม
      </div>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="48px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount">
        {{ beforeDiscount | currency2 }}
      </div>
    </div>

    <div v-if="isDeposit" class="checkout-summary-container">
      <div class="checkout-label">
        ชำระมัดจำ
      </div>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="48px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount">
        {{ depositTotal | currency2 }}
      </div>
    </div>

    <div class="checkout-summary-container">
      <div class="checkout-label">
        ส่วนลด
      </div>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="48px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount">
        - {{ discount | currency2 }}
      </div>
    </div>

    <div
      v-if="additionalDiscount > 0"
      class="checkout-summary-container"
    >
      <div class="checkout-label">
        {{ additionalDiscountLabel }}
      </div>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="48px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount">
        - {{ additionalDiscount | currency2 }}
      </div>
    </div>

    <!-- advancePaymentOptional -->
    <div
      v-if="advancePaymentOptional > 0"
      class="checkout-summary-container"
    >
      <div class="checkout-label">
        ชำระเพิ่มเติม
      </div>
      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="48px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount">
        {{ advancePaymentOptional | currency2 }}
      </div>
    </div>

    <!-- <div class="checkout-summary-container">
      <div class="checkout-label">
        ค่าจัดส่ง
      </div>
      <div class="checkout-amount">
        {{ cartSummaryData.shipping_cost | currency2 }}
      </div>
    </div> -->

    <hr class="divider-gray-85 my-6" />

    <div class="checkout-summary-container">
      <div class="checkout-label-bold">
        ยอดสุทธิ
      </div>

      <v-skeleton-loader
        v-if="isLoading"
        type="text"
        width="150px"
      ></v-skeleton-loader>
      <div v-else class="checkout-amount-bold">
        {{ finalPrice | currency2 }}
      </div>
    </div>

    <template v-if="advancePayment > 0">
      <div class="checkout-summary-container mb-4 mt-6">
        <div class="checkout-summary-box">
          <div class="checkout-summary-container mb-0">
            <div class="checkout-label">
              ชำระล่วงหน้า
            </div>
            <v-skeleton-loader
              v-if="isLoading"
              type="text"
              width="48px"
            ></v-skeleton-loader>
            <div v-else class="checkout-amount">
              {{ advancePayment | currency2 }}
            </div>
          </div>
        </div>
      </div>
    </template>

    <div
      v-if="isDeposit"
      class="checkout-summary-container mb-4 mt-6"
    >
      <div class="checkout-summary-box">
        <div class="checkout-summary-container mb-0">
          <div class="checkout-label">
            {{ depositLabel }}
          </div>
          <v-skeleton-loader
            v-if="isLoading"
            type="text"
            width="48px"
          ></v-skeleton-loader>
          <div v-else class="checkout-amount">
            {{ depositTotal | currency2 }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isDefault || isDeposit || isPickupAtStore"
      :class="[
        'checkout-summary-container mt-4',
        isDefault ? 'mb-4' : 'mb-0',
      ]"
    >
      <div class="checkout-summary-box">
        <div v-if="isDeposit" class="checkout-summary-container">
          <div class="checkout-label">
            ชำระยอดคงเหลือและรับสินค้าได้ที่สาขา
          </div>
          <v-skeleton-loader
            v-if="isLoading"
            type="text"
            width="48px"
          ></v-skeleton-loader>
          <div v-else class="checkout-amount text-primary">
            {{ remainingTotal | currency2 }}
          </div>
        </div>
        <div
          v-if="isDeposit || isPickupAtStore"
          :class="[
            'checkout-summary-container',
            isDefault ? 'mb-4' : 'mb-0',
          ]"
        >
          <div class="checkout-label">
            สาขา:
          </div>
          <v-skeleton-loader
            v-if="isLoading"
            type="text"
            width="48px"
          ></v-skeleton-loader>
          <div v-else class="checkout-amount">
            {{ branchNameSelected || branchName || '-' }}
          </div>
        </div>
        <div v-if="isDefault" class="checkout-summary-container mb-0">
          <div class="checkout-label">
            วันที่:
          </div>
          <v-skeleton-loader
            v-if="isLoading"
            type="text"
            width="48px"
          ></v-skeleton-loader>
          <div
            v-else
            :class="['checkout-amount', isShipDateHighlight]"
          >
            {{ shipDate }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isDefault && (isDeposit || isPickupAtStore)"
      class="checkout-summary-container"
    >
      <div class="alert-info">
        <v-icon size="16px">
          mdi-information-outline
        </v-icon>

        <div>
          *การจองและรับสินค้าที่สาขา บริษัทฯ
          ขอเช็คเปิดเครื่องเพื่อการรับประกันสินค้าทุกกรณี
          และขอสงวนสิทธิ์ยกเลิกคำสั่งซื้อหาลูกค้าไม่ประสงค์จะเปิดเช็คเครื่อง
        </div>
      </div>
    </div>

    <div
      v-if="isShowMarketingBanner"
      class="checkout-summary-container mt-4"
    >
      <div
        class="marketing-banner-html"
        v-html="marketingBannerHtml"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CheckoutSummary',
  props: {
    isShipDateHighlight: {
      type: String,
      default: '',
    },
    shipDate: {
      type: String,
      default: '',
    },
    cartSummaryData: {
      type: Object,
      default: () => ({}),
    },
    additionalDiscountLabel: {
      type: String,
      default: 'ส่วนลดเพิ่มเติม',
    },
    isDeposit: {
      type: Boolean,
      default: false,
    },
    isPickupAtStore: {
      type: Boolean,
      default: false,
    },
    branchName: {
      type: String,
      default: '',
    },
    isShowMarketingBanner: {
      type: Boolean,
      default: false,
    },
    isDefault: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      isLoading: state => state.cart.isLoading,
      branchType: state => state.branchStore.branchType,
      branchSelected: state => state.branchStore.branchSelect,

      marketingBannerHtml: state =>
        state.staticPage.data?.[0]?.html ?? '',
    }),

    ...mapGetters({
      branchNameSelected: 'branchStore/getBranchNameSelected',
    }),

    beforeDiscount() {
      return this.cartSummaryData?.before_discount ?? 0
    },

    discount() {
      return this.cartSummaryData?.summary_discount ?? 0
    },

    additionalDiscount() {
      return this.cartSummaryData?.additional_discount ?? 0
    },

    depositLabel() {
      return this.$route.name === 'Checkout'
        ? 'ยอดคงเหลือที่ต้องชำระเงินมัดจำล่วงหน้า'
        : 'ยอดชำระเงินมัดจำล่วงหน้า'
    },

    depositTotal() {
      return this.cartSummaryData?.product_price_deposit_summary ?? 0
    },

    remainingTotal() {
      return this.cartSummaryData?.remain_amount_original ?? 0
    },

    finalPrice() {
      return this.cartSummaryData?.final_price ?? 0
    },

    advancePayment() {
      return this.cartSummaryData?.advance_payment ?? 0
    },

    advancePaymentOptional() {
      return this.cartSummaryData?.advance_payment_optional ?? 0
    },
  },
}
</script>

<style lang="stylus">
.checkout-summary-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 8px;
    gap: 8px;

    .checkout-label,
    .checkout-amount {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;

        @media screen and (min-width: 1024px) {
            font-size: 16px
            line-height: 24px
        }
    }

    .checkout-label-bold,
    .checkout-amount-bold {
        font-family: "SF Pro Display";
        font-size: 16px
        font-weight: 500
        line-height: 24px

        @media screen and (min-width: 1024px) {
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem;
            letter-spacing: .03rem;

        }
    }
}
.checkout-summary-box {
    width 100%
    border: 1px solid $color-gray85;
    border-radius: 8px;
    padding: 18px;
}

.checkout-label {
    width: 50%
    max-width: 50%

    @media (min-width: 768px) {
        width: fit-content;
        max-width: fit-content;

    }
}

.checkout-amount,
.checkout-amount-bold {
    text-align: right
}

.alert-info {
    background-color: $color-bnn-light
    border-radius: 8px
    padding: 8px 16px
    font-size: 12px

    display: flex
    align-items: center
    gap: 8px

    .v-icon {
        color: $color-bnn
    }
}

.marketing-banner-html {
    max-width: 100%
    overflow: hidden
}
</style>
